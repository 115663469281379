import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavLink = ({ item }) => {
  return (
    <>
      <Links>
        <Link to={item.path}>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </Link>
      </Links>
    </>
  )
}

const Links = styled.div`
  display: flex;
  color: var(--clr-primary-6);
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    border-left: 4px solid var(--clr-primary-6);
    cursor: pointer;
  }
`

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: var(--clr-white);
  text-transform: capitalize;
`

export default NavLink
