import React, { useContext } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { HiArrowNarrowRight } from "react-icons/hi"

import { GatsbyContext } from "../../context/context"

const query = graphql`
  {
    allDatoCmsImage(
      filter: { image: { alt: { eq: "Kissane Associates Logo" } } }
    ) {
      nodes {
        image {
          alt
          gatsbyImageData(backgroundColor: "grey")
          title
        }
      }
    }

    allDatoCmsPracticeArea(sort: { order: ASC, fields: number }) {
      edges {
        node {
          slug
          title
        }
      }
    }
  }
`

const FooterTop = () => {
  const { links, infoLinks } = useContext(GatsbyContext)

  const {
    allDatoCmsImage: { nodes: image },
  } = useStaticQuery(query)

  const logo = image[0].image.gatsbyImageData
  const alt = image[0].image.alt

  const {
    allDatoCmsPracticeArea: { edges: practiceAreas },
  } = useStaticQuery(query)

  return (
    <Wrapper className="footer-top">
      <Container>
        <Row>
          <Col md={4}>
            <article>
              <Figure>
                <GatsbyImage image={logo} alt={alt} />
              </Figure>
              {infoLinks.map((item, index) => {
                return (
                  <>
                    <InfoBox key={index}>
                      <div className="footer-top__icon">{item.icon}</div>

                      <div className="footer-top__info">
                        <p>{item.info}</p>
                      </div>
                    </InfoBox>
                  </>
                )
              })}
            </article>
          </Col>

          <Col md={4}>
            <article className="footer-top__practice-areas">
              <h3 className="footer-top__heading">Practice Areas</h3>

              <nav>
                <ul>
                  {practiceAreas.map(({ node }) => {
                    const { slug, title } = node
                    return (
                      <li>
                        <HiArrowNarrowRight />
                        <span>
                          <Link to={`/practice-areas/${slug}`}>{title}</Link>
                        </span>
                      </li>
                    )
                  })}
                </ul>
              </nav>
            </article>
          </Col>

          <Col md={4}>
            <article className="footer-top__links">
              <h3 className="footer-top__heading">Links</h3>

              <nav>
                <ul>
                  {links.map(link => (
                    <li>
                      {link.icon}
                      <Link to={link.path}>{link.title}</Link>
                    </li>
                  ))}
                </ul>
              </nav>

              <nav className="footer__socials"></nav>
            </article>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  position: relative;

  .footer-top__heading {
    margin: 2rem 0 2rem;
    /* margin: 0 0 2rem; */
  }

  .footer-top__practice-areas {
    position: relative;
  }

  .footer-top__practice-areas li {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    position: relative;
    padding: 7px 0;

    svg {
      color: var(--clr-primary-6);
    }

    span {
      color: var(--clr-secondary-1);

      @media screen and (max-width: 480px) {
        text-align: center;
      }
    }

    span a {
      color: var(--clr-secondary-1);
    }

    a:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      color: var(--clr-primary-7);
    }
  }

  @media only screen and (max-width: 600px) {
    .footer-top__practice-areas,
    .footer-top__practice-areas li,
    .footer-top__links,
    .footer-top__links li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .footer-top__practice-areas li {
      grid-template-columns: 1fr;
    }

    svg {
      text-align: center;
    }

    .footer-top__links li a {
      padding-left: 0 !important;
    }
  }

  @media screen and (max-width: 200px) {
    .footer-top__practice-areas li {
      text-align: center;
    }
  }

  .footer-top__links li {
    padding: 7px 0;

    svg {
      color: var(--clr-primary-6);
    }

    a {
      color: var(--clr-secondary-1);
      padding-left: 1rem;
      text-decoration: none;
      text-transform: capitalize;
    }

    a:hover {
      text-decoration: none;
      color: var(--clr-primary-6);
    }
  }

  .footer-top__heading {
    color: var(--clr-secondary-1);
  }
`

const Figure = styled.figure`
  width: 16rem;

  @media only screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
  }
`

const InfoBox = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1rem;
  position: relative;
  padding: 7px 0;

  .footer-top__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: var(--clr-secondary-1);
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;

    .footer-top__info {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .footer-top__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-top__icon svg {
    font-size: 1rem;
    color: var(--clr-primary-6);
  }
`

export default FooterTop
