import React from "react"
import styled from "styled-components"

import Navbar from "./Navbar"
import Footer from "./Footer/Footer"

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <Main>
        {children}
        <Footer />
      </Main>
    </>
  )
}

const Main = styled.main`
  position: relative;
`

export default Layout
