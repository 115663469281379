import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"

const FooterBottom = () => {
  return (
    <Wrapper className="footer-bottom">
      <Container>
        <nav className="navigation">
          <ul className="footer-top__links"></ul>
        </nav>
        <article>
          <p className="footer-bottom__copy-rights">
            &copy; Kissane Associates {new Date().getFullYear()} All rights
            reserved. -- Web design & Web development by{" "}
            <a href="https://www.clicksdesign.agency" target="blank">
              Clicks Design
            </a>
          </p>
        </article>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  background: var(--clr-primary-9);
  position: relative;
  text-align: center;
  width: 100%;
  padding: 1rem;

  p {
    color: var(--clr-secondary-1);
    margin: 0;
  }
`

export default FooterBottom
