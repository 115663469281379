import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  {
    site {
      siteMetadata {
        author
        description
        image
        siteUrl
        title
        titleTemplate
      }
    }

    datoCmsImage(
      image: { alt: { eq: "Kissane Associates, St. Alban Vermont" } }
    ) {
      image {
        alt
        gatsbyImageData
      }
    }
  }
`

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site, datoCmsImage: img } = useStaticQuery(query)
  const metaDescription = description || site.siteMetadata.description

  const seoImage = img.image.gatsbyImageData.images.fallback.src

  const {
    author,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    author: author || author,
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={seo.title}
      titleTemplate={titleTemplate}
      meta={[
        { name: `description`, content: metaDescription },
        { name: `image`, content: image },
      ]}
    >
      <meta name="author" content={seo.author} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seoImage} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seoImage && <meta property="og:image" content={seoImage} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={defaultTitle} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seoImage && <meta name="twitter:image" content={seoImage} />}
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}

export default SEO
