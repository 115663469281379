import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { Container, Row, Col, Button } from "react-bootstrap"

const query = graphql`
  {
    allDatoCmsHero {
      edges {
        node {
          button
          id
          image {
            alt
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "grey")
          }
          title
          subTitle
        }
      }
    }
  }
`

const Hero = () => {
  const {
    allDatoCmsHero: { edges },
  } = useStaticQuery(query)

  const hero = edges

  return (
    <Row>
      <Col>
        {hero &&
          hero.map(({ node }) => {
            const image = getImage(node.image.gatsbyImageData)

            // Use like this:
            const bgImage = convertToBgImage(image)
            return (
              <Wrapper>
                <BackgroundImage
                  Tag="header"
                  {...bgImage}
                  preserveStackingContext
                >
                  <GatsbyImage image={image} alt={node.image.alt} />
                  <Container className="content-container">
                    <Row key={node.id}>
                      <Col md={{ span: 10, offset: 1 }}>
                        <Article>
                          <h1>
                            {node.title}
                            <span className="highlight">{node.titleSpan}</span>
                          </h1>
                          <p className="lead mb-4">{node.subTitle}</p>
                          <a href="tel:802-524-9546">
                            <Button
                              className="btn-primary custom-button"
                              size="lg"
                            >
                              {node.button}
                            </Button>
                          </a>
                        </Article>
                      </Col>
                    </Row>
                  </Container>
                </BackgroundImage>
              </Wrapper>
            )
          })}
      </Col>
    </Row>
  )
}

export default Hero

const Wrapper = styled.div`
  place-items: center;
  position: relative;
  text-align: center;
  color: var(--clr-secondary-1);

  .masthead {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    text-align: center;
  }

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    height: 100vh;
  }

  .content-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Article = styled.article`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 5rem;

  @media only screen and (max-width: 480px) {
    padding-top: 0;
  }

  h1 {
    position: relative;
    margin-bottom: 2.75rem;
    font-size: 4rem;
    font-weight: 900;
    color: var(--clr-primary-6);
    /* .highlight {
      position: relative;
      color: var(--clr-primary-6);
      font-size: 4rem;
      display: block;
    } */

    @media only screen and (max-width: 480px) {
      font-size: 3rem;
      padding-top: 0;
      margin-bottom: 1rem;
    }
  }

  /* Landscape */
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    font-size: 3rem;
    padding-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 480px) {
    .lead {
      font-size: 1rem;
      font-weight: 300;
    }
  }

  p {
    color: var(--clr-secondary-1);
  }

  @media screen and (max-width: 200px) {
    p.lead.mb-4 {
      font-size: 9vw;
    }
  }
`
