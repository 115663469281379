import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { Container, Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"

import Title from "./Title"

const query = graphql`
  {
    allDatoCmsAttorney {
      edges {
        node {
          imageProfile {
            alt
            gatsbyImageData
          }
          attorney {
            ... on DatoCmsTitle {
              id
              title
              slug
            }
            ... on DatoCmsTel {
              id
              tel
              ext
            }
          }
        }
      }
    }

    allDatoCmsImage(filter: { image: { alt: { eq: "law's book" } } }) {
      nodes {
        image {
          alt
          gatsbyImageData
          title
        }
      }
    }
  }
`

const Attorneys = () => {
  const {
    allDatoCmsAttorney: { edges: attorneys },
    allDatoCmsImage: { nodes: image },
  } = useStaticQuery(query)

  const img = image[1].image.gatsbyImageData
  const useImage = getImage(img)
  const bgImage = convertToBgImage(useImage)
  const alt = image[1].image.alt

  return (
    <Wrapper>
      <BackgroundImage
        className="section-background-image section"
        alt={alt}
        Tag="section"
        {...bgImage}
        preserveStackingContext
        fadeIn
      >
        <Container>
          <Row>
            <Col>
              <Title title="Meet Our Attorneys" />
            </Col>
          </Row>

          <div className="section-center">
            <Row>
              {attorneys.map(({ node }) => {
                const image = getImage(node.imageProfile.gatsbyImageData)
                const title = node.attorney[0].title
                const slug = node.attorney[0].slug
                const tel = node.attorney[1].tel
                  .toString()
                  .replace(/(\d{3})(\d{3})(\d{4})/, "$1.$2.$3")

                const ext = node.attorney[1].ext

                return (
                  <Col md={4}>
                    <div className="team-member">
                      <article>
                        <GatsbyImage
                          image={image}
                          alt={node.imageProfile.alt}
                        />
                        <div className="team-desc overlay">
                          <div className="member-info">
                            <h5>{title}</h5>
                            <p>
                              Tel: {tel} ext: <span>{ext}</span>
                            </p>
                          </div>
                          <Link to={`/attorneys/${slug}`}>
                            <Button variant="light">View Profile</Button>
                          </Link>
                        </div>
                      </article>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        </Container>
      </BackgroundImage>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-top: 4rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .section-center {
    margin-top: 4rem;
    text-align: center;
    position: relative;
    overflow: hidden;

    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: var(--transition);
      display: contents;
    }

    .team-member {
      width: 100%;
      position: relative;
      overflow: hidden;
      display: inline-block;

      @media screen and (max-width: 800px) {
        margin-bottom: 2rem;
      }
    }

    .team-member:hover .team-desc.overlay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 28px 27px 56px;
      height: 100%;
      top: 0;
      margin-top: -0px;
    }

    .team-desc.overlay {
      display: table;
      position: absolute;
      z-index: 100;
      width: 100%;
      height: 70px;
      padding: 12px 27px;
      background-color: rgb(182, 157, 116, var(--alpha));
      bottom: 0px;
      top: 100%;
      margin-top: -70px;
      transition: all 0.5s ease;
    }

    .member-info,
    .member-info p {
      color: var(--clr-secondary-1);
    }
  }

  h2.section-heading {
    color: var(--clr-secondary-1);
  }

  .section-heading::after {
    content: "";
    display: block;
    position: relative;
    height: 3px;
    width: 30px;
    background-color: var(--clr-primary-6);
    margin: 40px auto;
  }
`

export default Attorneys
