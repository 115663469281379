import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"

const CallToAction = () => {
  return (
    <Container>
      <Row>
        <Col>
          <Wrapper className="section no-padding-top">
            <h3>Call Now For A Free Consultation</h3>

            <a href="tel:802-524-9546" className="phone-link">
              <Button className="btn-primary custom-button btn btn-primary btn-lg">
                802-524-9546
              </Button>
            </a>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  )
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding: 0 0 5rem 0;

  h3 {
    padding-right: 2rem;

    @media screen and (max-width: 480px) {
      padding-right: 0rem;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    ustify-content: center;
    align-items: center;
    text-align: center;
  }
`

export default CallToAction
