import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { FaPhoneAlt } from "react-icons/fa"

import { GatsbyContext } from "../context/context"
import NavLink from "./NavLink"

const query = graphql`
  {
    allDatoCmsImage(
      filter: { image: { alt: { eq: "Kissane Associates Logo" } } }
    ) {
      nodes {
        image {
          alt
          gatsbyImageData
        }
      }
    }
  }
`

const Navigationbar = () => {
  const { links } = useContext(GatsbyContext)

  const {
    allDatoCmsImage: { nodes: image },
  } = useStaticQuery(query)

  const logo = image[0].image.gatsbyImageData
  const useLogo = getImage(logo)
  const alt = image[0].image.alt

  return (
    <>
      <Wrapper>
        <div className="header-top">
          <Container>
            <Row>
              <Col sm className="text-center">
                <a href="tel:802-524-9546" rel="noreferrer">
                  <FaPhoneAlt /> 802-524-9546
                </a>
                <span>
                  No fee for initial consultation on personal injury and
                  wrongful death claims, go to extension 15
                </span>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="header-lower">
          <Container>
            <Navbar collapseOnSelect expand="lg" variant="dark">
              <Navbar.Brand href="/">
                <figure>
                  <GatsbyImage image={useLogo} alt={alt} />
                </figure>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="ml-auto"
              />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  {links &&
                    links.map((item, index) => {
                      return <NavLink item={item} key={index} />
                    })}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: relative;

  .header-top {
    background: var(--clr-primary-9);
    width: 100%;
    padding: 16px 0;
    color: var(--clr-white);
    z-index: 2;

    span {
      padding-left: 2rem;
    }
  }

  .header-lower {
    background: none;
    width: 100%;

    position: absolute;
    z-index: 2;

    figure {
      width: 30%;
      margin-bottom: 0;
      padding: 2rem;

      @media screen and (max-width: 480px) {
        padding: 0 !important;
      }

      @media screen and (max-width: 800px) {
        padding: 1rem;
      }
    }
  }

  @media screen and (max-width: 990px) {
    .navbar-nav {
      background: var(--clr-primary-8);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  @media only screen and (max-width: 600px) {
    .header-lower figure {
      width: 50%;
    }
  }

  .navbar-brand {
    padding-bottom: 0;
    display: contents;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: var(--clr-grey-8);
  }

  .navbar-toggler {
    background-color: var(--clr-primary-6);
  }

  @media screen and (max-width: 200px) {
    nav.navbar.navbar-expand-lg.navbar-dark {
      display: flex;
      justify-content: center;
    }

    button.ml-auto.navbar-toggler.collapsed {
      margin-left: 0 !important;
    }

    .header-lower figure {
      width: 100%;
    }
  }
`

export default Navigationbar
