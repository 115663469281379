import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Container, Row, Col, Card } from "react-bootstrap"
import Title from "./Title"

export const query = graphql`
  {
    allDatoCmsPracticeArea(sort: { order: ASC, fields: number }) {
      edges {
        node {
          slug
          icon {
            alt
            gatsbyImageData(placeholder: BLURRED, backgroundColor: "grey")
          }
          id
          excerpt
          title
        }
      }
    }

    allDatoCmsPageIntro(filter: { title: { eq: "Practice Areas Page" } }) {
      edges {
        node {
          id
          info
          title
        }
      }
    }
  }
`

const PraticeAreas = () => {
  const {
    allDatoCmsPracticeArea: { edges: practiceAreas },
    allDatoCmsPageIntro: { edges: intro },
  } = useStaticQuery(query)

  return (
    <Wrapper className="section">
      <Container>
        <Row>
          <Col>
            <Title title="Practice Areas" />

            {intro.map(({ node }) => {
              return <p className="section-intro">{node.info}</p>
            })}
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="section-center">
              <Row>
                {practiceAreas.map(({ node }) => {
                  const { icon, excerpt, title, slug } = node
                  const praticeImag = icon.gatsbyImageData

                  return (
                    <Col
                      md={4}
                      className="card-column flex-fill col-md-6 col-sm-12"
                    >
                      <article>
                        <nav>
                          <Link to={`/pratice-areas/${slug}`}>
                            <Card>
                              <Card.Body>
                                <GatsbyImage
                                  image={praticeImag}
                                  alt={icon.alt}
                                />
                                <h4 className="card-title">{title}</h4>
                                <Card.Text>
                                  <div
                                    className="card-text-body"
                                    dangerouslySetInnerHTML={{
                                      __html: excerpt,
                                    }}
                                  ></div>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Link>
                        </nav>
                      </article>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .card-column {
    margin-bottom: 2rem;
    display: flex;
  }

  @media only screen and (max-width: 400px) {
    .card-column {
      display: block !important;
    }
  }

  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
    display: contents;
  }

  .card {
    position: relative;
    text-align: center;
    height: 100%;
    position: relative;
    transition: all 0.4s ease;

    :hover {
      background-color: rgba(38, 48, 67, var(--alpha));
      .card-title {
        color: var(--clr-primary-6);
      }

      .card-body > .card-text > .card-text-body {
        color: var(--clr-secondary-1);
      }

      .card-body > .card-text > .card-text-body p span {
        color: var(--clr-grey-3);
      }
    }
  }

  .card .card-title {
    color: var(--clr-grey-1);
    font-weight: 900;
    text-transform: uppercase;
  }

  .card-body::after {
    content: "";
    top: 156px;
    position: absolute;
    background: var(--clr-primary-6);
    height: 30px;
    width: 1px;
    display: inline-block;
    transition: all 0.4s ease;
  }

  .card-body:hover::after {
    top: 0;
    position: absolute;
    background: var(--clr-primary-6);
    transition: all 0.4s ease;
  }

  .card-body:hover > .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    top: 5rem;
  }

  .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
    width: 6rem;

    &:not(:last-child) {
      margin-bottom: 108px;
    }

    &:hover {
      top: 5rem;
    }
  }
  /* .section-center {
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;

    article {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: var(--transition);
      display: contents;
    }

    @media (max-width: 1200px) {
      .d-flex {
        display: grid !important;
      }
    }

    .card-column {
      margin-bottom: 1rem;
    }

    .card {
      position: relative;
      text-align: center;
      height: 100%;
      position: relative;
      transition: all 0.4s ease;
      flex: 0 0 50%;

      @media (max-width: 1200px) {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: 800px) {
        width: 100%;
        max-width: 100%;
      }

      :hover {
        background-color: rgba(38, 48, 67, var(--alpha));
        .card-title {
          color: var(--clr-primary-6);
        }

        .card-body > .card-text > .card-text-body {
          color: var(--clr-secondary-1);
        }
      }
    }

    .card .card-title {
      color: var(--clr-grey-1);
      font-weight: 900;
      text-transform: uppercase;
    }

    .card-body::after {
      content: "";
      top: 156px;
      position: absolute;
      background: var(--clr-primary-6);
      height: 30px;
      width: 1px;
      display: inline-block;
      transition: all 0.4s ease;
    }

    .card-body:hover::after {
      top: 0;
      position: absolute;
      background: var(--clr-primary-6);
      transition: all 0.4s ease;
    }

    .card-body:hover > .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      top: 5rem;
    }

    .gatsby-image-wrapper.gatsby-image-wrapper-constrained {
      width: 6rem;

      &:not(:last-child) {
        margin-bottom: 108px;
      }

      &:hover {
        top: 5rem;
      }
    }
  } */

  .section-intro {
    text-align: center;
  }

  /* Swpier */
  /* .swiper-slide {
    flex: 0 0 33.333333%;
    width: 33.333333% !important;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: 0 !important;
  }

  @media (max-width: 576px) {
    .swiper-slide {
      flex: none;
      width: 100% !important;
    }
  }

  @media screen and (max-width: 640px) {
    .swiper-slide {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 768px) {
    .swiper-slide {
      width: 100% !important;
    }
  }

  @media (min-width: 1200px) {
    .swiper-slide {
      width: 100% !important;
    }
  } */
`

export default PraticeAreas
