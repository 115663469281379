import React from "react"
import styled from "styled-components"

import FooterBottom from "./FooterBottom"
import FooterTop from "./FooterTop"

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTop />
      <FooterBottom />
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  background: var(--clr-primary-8);
  position: relative;
  padding-top: 100px;
`

export default Footer
