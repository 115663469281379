import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import styled from "styled-components"

import Title from "./Title"

const query = graphql`
  {
    allDatoCmsWelcome {
      edges {
        node {
          id
          sectionTitle
          info
          button
        }
      }
    }
  }
`

const Welcome = () => {
  const {
    allDatoCmsWelcome: { edges: welcome },
  } = useStaticQuery(query)

  return (
    <Container>
      <Row>
        <Col>
          <Section className="page-section">
            {welcome &&
              welcome.map(({ node }) => {
                return (
                  <>
                    <Title key={node.id} title={node.sectionTitle} />
                    <Col md={{ span: 8, offset: 2 }}>
                      <div
                        className="blog-template__text-body"
                        dangerouslySetInnerHTML={{
                          __html: node.info,
                        }}
                      ></div>
                      <Link to={`/about`}>
                        <Button className="btn-primary custom-button">
                          Learn more about us
                        </Button>
                      </Link>
                    </Col>
                  </>
                )
              })}
          </Section>
        </Col>
      </Row>
    </Container>
  )
}

const Section = styled.section`
  text-align: center;
`

export default Welcome
